<template>
	<div class="business-create">
		<w-navTab titleText="线下任务"></w-navTab>
		<div class="container">
			<div class="head-form">
				<div class="head-text">{{taskDetail.name}}</div>
			</div>
			<div class="middle-text">
				<textarea placeholder="请输入内容" v-model="content"></textarea>
				<div class="middle-footer-left">
					<div>({{ content.length }}/1000)</div>
				</div>
				<div class="uploader">
					<div class="uploader-item" v-for="(vo, index) in picture">
						<img class="uploader-close" src="@/assets/img/uploader_icon_close.png"
							@click="deletePicture(index)" />
						<img class="uploader-image" :src="vo.url" />
					</div>
					<div class="uploader-item uploader-item-select">
						<van-uploader :after-read="onSelect" v-show="isShowUploader" upload-icon="plus"></van-uploader>
					</div>
				</div>
			</div>
			<div class="create-footer">
				<div class="footer">
					<div class="footer-left">
						<div class="footer-left-text">提交后</div>
						<div class="footer-left-title">+{{ taskDetail.love_value }}爱心值</div>
					</div>
					<div class="footer-btn" @click="onSubmit">提交任务</div>
				</div>
			</div>
			<van-popup v-model="isShowSelectGroup" position="bottom">
				<van-picker title="请选择" show-toolbar value-key="title" :columns="groupList" @confirm="onConfirmGroup"
					@cancel="onCancelGroup" />
			</van-popup>
		</div>
	</div>
</template>

<script>
	import UploadApi from '@/api/upload';
	import BusinessApi from '@/api/business';
	import VantVendor from '@/vendor/vant';
	import Task from '@/api/task';
	import UserApi from '@/api/user';
	export default {
		name: 'BusinessCreate',
		data() {
			return {
				picture: [],
				title: '',
				content: '',
				groupList: [],
				groupId: '',
				groupText: '',
				isShowUploader: true,
				isShowSelectGroup: false,
				needLoveValue: 0,
				taskDetail: ""
			};
		},
		watch: {
			content(newVal, oldVal) {
				if (newVal.length > 1000) {
					this.content = this.content.substring(0, 1000);
				}
			},
			picture(newVal, oldVal) {
				this.isShowUploader = newVal.length < 4;
			}
		},
		created() {
			this.groupId = this.$route.params.id
			Task.offlineTaskDetail({
					group_id: this.groupId
				})
				.then(result => {
					this.taskDetail = result.data
				})
				.catch(error => {})

			UserApi.getUserBasicInfo()
				.then(result => {
					if (result.data.role_type == 1) {
						VantVendor.Dialog.alert({
							title: '提示',
							message: "权限不足，请先升级为平安志愿者或义警！",
							theme: 'round-button',
						}).then(() => {
							this.$router.go(-1)
						});
					}
				})
		},
		methods: {
			onConfirmGroup(selected) {
				this.onCancelGroup();
				this.groupId = selected.id;
				this.groupText = selected.title;
			},
			onCancelGroup() {
				this.isShowSelectGroup = false;
			},
			onSubmit() {
				let params = {
					group_id: this.groupId,
					title: this.taskDetail.name,
					content: this.content,
					picture: this.picture.map(item => item.path).join()
				};
				Task.offlineTaskAdd(params)
					.then(result => {
						VantVendor.Toast("提交成功,请耐心等待管理员审核。");
						setTimeout(() => {
							this.$router.go(-1)
						}, 1000);
					})
					.catch(error => {
						VantVendor.Toast.fail(error.msg);
					})
			},
			onSelect(selected) {
				VantVendor.Toast.loading('上传中');
				UploadApi.image(selected.file)
					.then(result => {
						this.picture.push({
							url: result.data.fileUrl,
							path: result.data.filePath
						});
					})
					.catch(error => {
						VantVendor.Toast.fail(error.msg);
					})
					.finally(() => {
						VantVendor.Toast.clear();
					});
			},
			deletePicture(index) {
				this.picture.splice(index, 1);
			}
		}
	};
</script>

<style scoped lang="less">
	.business-create {
		position: relative;

		.container {
			padding: 10px;
			box-sizing: border-box;

			.head-form {
				.head-text {
					padding: 10px;
					background-color: #fff;
					border-radius: 6px;
					margin-bottom: 10px;
					font-size: 16px;
					font-weight: bold;
					text-align: center;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}

			.head-top {
				padding: 10px;
				padding-bottom: 0;
				background-color: #fff;
				border-radius: 6px;

				.head-middle {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding-bottom: 12px;

					.head-top-left {
						.head-top-text {
							font-size: 14px;
							line-height: 28px;
							color: #666;
						}

						.head-top-title {
							font-size: 18px;
							line-height: 26px;
							color: #000;
						}
					}

					.head-top-right {
						display: flex;
						justify-content: flex-start;
						align-items: center;

						.right-text {
							font-size: 14px;
							line-height: 26px;
							color: #666;
						}
					}
				}

				.head-top-bottom {
					margin-top: -20px;
					height: 13px;

					.head-top-bottom-picture {
						width: 100%;
						height: 2px;

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
				}
			}

			.head-empty-address {
				width: 100%;
				height: 60px;
				line-height: 60px;
				background-color: #fff;
				border-radius: 6px;
				display: flex;
				justify-content: center;
				align-items: center;

				.head-empty-address-text {
					margin-left: 5px;
					font-size: 16px;
					color: #666;
				}

				.van-icon {
					font-size: 18px;
					color: #666;
				}
			}

			.middle-text {
				width: 100%;
				height: 100%;
				padding: 10px;
				background-color: #fff;
				box-sizing: border-box;
				border-radius: 6px;
				margin-top: 10px;
				position: relative;

				textarea {
					width: 100%;
					height: 224px;
					background-color: #fff;
					border: 0;
					padding: 0;
					font-size: 16px;
				}

				.middle-footer-left {
					position: absolute;
					right: 20px;
					top: 239px;
					font-size: 14px;
					line-height: 26px;
					color: #666;
				}

				.uploader {
					display: flex;
					margin-top: 36px;

					.uploader-item {
						position: relative;
						margin-right: 12px;

						&:last-child {
							margin-right: 0;
						}

						.uploader-close {
							position: absolute;
							top: 5px;
							right: 5px;
							width: 16px;
							height: 16px;
						}

						.uploader-image {
							width: 75px;
							height: 75px;
							object-fit: cover;
							border-radius: 6px;
						}
					}

					.uploader-item-select {
						position: relative;

						/deep/ .van-uploader__upload {
							width: 75px;
							height: 75px;
							margin: 0;
							border-radius: 6px;
							background-color: #f2f2f2;

							.van-icon {
								font-size: 30px;
								color: #aaa;
							}
						}
					}
				}
			}

			.create-footer {
				position: absolute;
				left: 0;
				bottom: 10px;
				padding: 0 10px;
				width: 100%;
				box-sizing: border-box;

				.footer {
					padding: 10px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					background: #fff;
					border-radius: 6px;

					.footer-left {
						display: flex;
						justify-content: flex-start;
						align-items: center;

						.footer-left-text {
							font-size: 14px;
							line-height: 26px;
							color: #666;
						}

						.footer-left-title {
							font-size: 14px;
							line-height: 26px;
							color: #3377ff;
							font-weight: bold;
						}
					}

					.footer-btn {
						padding: 3px 16px;
						font-size: 14px;
						line-height: 26px;
						color: #fff;
						background: #3377ff;
						border-radius: 100px;
					}
				}
			}
		}
	}
</style>
